<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap">
        <h1 class="tit-h1 d-flex">
          캠페인설문현황
          <!-- 조회 버튼 -->
          <div class="ml-auto align-self-center">
            <v-btn class="btn-etc2" outlined small @click="getListData">조회</v-btn>
          </div>
        </h1>
        <!-- 조회 -->
        <div class="box-ct mt-2">
          <div class="table-form d-flex">
            <table>
              <caption class="hide">
                조회 항목
              </caption>
              <colgroup>
                <col width="148px" />
                <col width="220px" />
                <col width="148px" />
                <col width="240px" />
                <col width="148px" />
                <col width="240px" />
                <col width="148px" />
                <col width="190px" />
                <col width="148px" />
                <col width="150px" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <v-badge dot color="#EF9191">
                      건설사
                    </v-badge>
                  </th>
                  <td>
                    <div class="form-inp full">
                      <v-autocomplete
                        v-model="CTCP_CODE"
                        :items="ctcpTypeCd"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        hide-no-data
                        placeholder="선택하세요"
                        @change="changeBussType(CTCP_CODE)"
                        >
                      </v-autocomplete>
                    </div>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">
                      사업지명
                    </v-badge>
                  </th>
                  <td>
                    <div class="form-inp full">
                      <v-autocomplete
                        v-model="BUSS_CODE"
                        :items="bussTypeCd"
                        item-text="CD_NM"
                        item-value="CD"
                        outlined
                        hide-details
                        hide-no-data
                        placeholder="선택하세요"
                        :disabled="bussTypeCd.length === 0 ? true : false"
                        @change="changeCmpType(BUSS_CODE)"
                      >
                      </v-autocomplete>
                    </div>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">
                      캠페인명
                    </v-badge>
                  </th>
                  <td>
                    <div class="form-inp full">
                      <v-autocomplete
                        v-model="CMP_ID"
                        :items="cmpTypeCd"
                        item-text="CMP_NAME"
                        item-value="CMP_ID"
                        outlined
                        hide-details
                        hide-no-data
                        placeholder="선택하세요"
                        :disabled="cmpTypeCd.length === 0 ? true : false"
                      >
                      </v-autocomplete>
                    </div>
                  </td>
                  <th scope="row">
                    <v-badge dot color="#EF9191">
                      조회기간
                    </v-badge>
                  </th>
                  <td>
                    <v-menu 
                      v-model="menu1" 
                      :close-on-content-click="false" 
                      :nudge-left="290"
                      transition="scale-transition" 
                      offset-y 
                      min-width="auto" 
                      content-class="calendar-modal"
                      color="#FFF"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="form-inp full icon-calendar"
                          v-model="dateRangeText"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          outlined
                          hide-details
                          append-icon="svg-calendar"
                        ></v-text-field>
                      </template>
                      <div class="datepicker-range">
                        <v-date-picker
                          v-model="DATE_FROM"
                          no-title
                          color="#F4B228"
                          locale="ko-KR"
                          :max="DATE_TO"
                          :first-day-of-week="0" 
                          :day-format="mixin_getDate" 
                          @change="startDate"
                        ></v-date-picker>
                        <v-date-picker
                          v-model="DATE_TO"
                          no-title
                          color="#F4B228"
                          locale="ko-KR"
                          :first-day-of-week="0" 
                          :min="DATE_FROM"
                          :day-format="mixin_getDate" 
                          @change="endDate"
                        ></v-date-picker>
                      </div>
                      <div class="text-center pt-3 pb-3">
                        <v-btn outlined small class="btn-point ml-3" @click="menu1 = false">확인</v-btn>
                      </div>
                    </v-menu>
                  </td>
                  <th scope="row">
                    설문지
                  </th>
                  <td class="d-flex align-center">
                    <v-radio-group
                      v-model="TRGT_GUB_CD"
                      row
                      class="radio-inline ml-3"
                    >
                      <v-radio
                        value="CUST_TRGT_CD_01"
                        label="사전"
                        on-icon="svg-rad-on"
                        off-icon="svg-rad-off"
                      ></v-radio>
                      <v-radio
                        value="CUST_TRGT_CD_02"
                        label="방문"
                        on-icon="svg-rad-on"
                        off-icon="svg-rad-off"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
              </tbody> 
            </table>
          </div>
        </div>
        <!-- 목록 -->
        <div class="box-ct mt-3" style="min-height: 655px;">
          <h1 class="tit-h1 d-flex">
            캠페인 설문현황
          </h1>
          <div class="mt-2">
            <v-data-table
              dense
              v-model="selGroupCd"
              :headers="gridDataHeaders"
              :items="gridDataText"
              :items-per-page="itemsPerPage"
              item-key="index"
              :page.sync="page"
              hide-default-footer
              @page-count="pageCount = $event"
              fixed-header
              class="grid-default"
              height="130px"
              no-data-text="검색된 결과가 없습니다."
            >
            </v-data-table>
          </div>
          <template>
            <div v-if="cmpQutItem.length !== 0" :class="'mo-reserve-wrap--survey-form'">
              <div :class="'mo-reserve-wrap--title mb-3'">
                <p>{{ QUTN_NAME }}</p>
              </div>
              <div :class="'mo-reserve-wrap--survey-form-list'" v-for="(surveyItem, idx) in cmpQutItem" :key="idx">
                <div :class="'mo-reserve-wrap--survey-form-title'" style="font-size: large;">
                  <p v-html="surveyItem.QUTN_ITEM_NAME"></p>
                </div>
                <div :class="'mo-reserve-wrap--survey-form-subtext'">
                  <p v-html="surveyItem.QUTN_ITEM_CONT"></p>
                </div>
                <div :class="'mo-reserve-wrap--survey-form-a mb-3'" readonly>
                  <!-- 라디오 -->
                  <div class="form-inp pt-0 d-flex" v-if="surveyItem.QUTN_ITEM_TYPE_CD === 'QUTN_ITEM_TYPE_CD_10' ? true : false" style="width: 100%;">
                    <div style="width: 100%;">
                      <v-text-field 
                        v-for="(qdItem, index) in surveyItem.QUTN_DETL_ITEMS"
                        :key="qdItem.QUTN_ITEM_DETL_CODE+'_'+index"
                        :value="qdItem.QUTN_ITEM_DETL_CONT"
                        :name="surveyItem.QUTN_ITEM_CODE"
                        outlined
                        readonly
                      >
                      </v-text-field>
                    </div>
                    <div style="width: 100%;">
                      <v-text-field 
                        v-for="(qdItem, index) in surveyItem.QUTN_DETL_ITEMS"
                        :key="qdItem.QUTN_ITEM_DETL_CODE+'_'+index"
                        :value="qdItem.QUTN_DETL_CNT"
                        :name="surveyItem.QUTN_ITEM_CODE"
                        outlined
                        readonly
                      >
                      </v-text-field>
                    </div>
                  </div>
                  <!-- 체크 -->
                  <div class="form-inp pt-0 d-flex" v-else-if="surveyItem.QUTN_ITEM_TYPE_CD === 'QUTN_ITEM_TYPE_CD_11'" style="width: 100%;">
                    <div style="width: 100%;">
                      <v-text-field
                        v-for="(qdItem, index) in surveyItem.QUTN_DETL_ITEMS"
                        :key="qdItem.QUTN_ITEM_DETL_CODE+'_'+index"
                        :value="qdItem.QUTN_ITEM_DETL_CONT"
                        :name="surveyItem.QUTN_ITEM_CODE"
                        outlined
                        readonly
                      >
                      </v-text-field>
                    </div>
                    <div style="width: 100%;">
                      <v-text-field 
                        v-for="(qdItem, index) in surveyItem.QUTN_DETL_ITEMS"
                        :key="qdItem.QUTN_ITEM_DETL_CODE+'_'+index"
                        :value="qdItem.QUTN_DETL_CNT"
                        :name="surveyItem.QUTN_ITEM_CODE"
                        outlined
                        readonly
                      >
                      </v-text-field>
                    </div>
                  </div>
                  <!-- 셀렉트 -->
                  <div class="form-inp pt-0 d-flex" v-else-if="surveyItem.QUTN_ITEM_TYPE_CD === 'QUTN_ITEM_TYPE_CD_12' ? true : false" style="width: 100%;">
                    <div style="width: 100%;">
                      <v-text-field 
                        class="tft"
                        v-for="(qdItem, index) in surveyItem.QUTN_DETL_ITEMS"
                        :key="qdItem.QUTN_ITEM_DETL_CODE+'_'+index"
                        :value="qdItem.QUTN_ITEM_DETL_CONT"
                        :name="surveyItem.QUTN_ITEM_CODE"
                        outlined
                        readonly
                      >
                      </v-text-field>
                    </div>
                    <div style="width: 100%;">
                      <v-text-field 
                        v-for="(qdItem, index) in surveyItem.QUTN_DETL_ITEMS"
                        :key="qdItem.QUTN_ITEM_DETL_CODE+'_'+index"
                        :value="qdItem.QUTN_DETL_CNT"
                        :name="surveyItem.QUTN_ITEM_CODE"
                        outlined
                        readonly
                      >
                      </v-text-field>
                    </div>
                  </div>
                  <!-- 텍스트 -->
                  <div class="form-inp pt-0 d-flex" v-else-if="surveyItem.QUTN_ITEM_TYPE_CD === 'QUTN_ITEM_TYPE_CD_20' ? true : false" style="width: 100%;">
                    <div style="width: 100%;">
                      <v-text-field
                        value="주관식 응답"
                        :name="surveyItem.QUTN_ITEM_CODE"
                        outlined
                        readonly
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex" style="width: 100%;">
                      <v-text-field
                        :value="surveyItem.QUTN_DETL_CNT"
                        :name="surveyItem.QUTN_ITEM_CODE"
                        outlined
                        readonly
                      >
                      </v-text-field>
                      <div class="ml-auto" style="width: 100%;">
                        <v-btn v-if="surveyItem.QUTN_DETL_CNT !== '0'"
                          outlined 
                          small
                          class="btn-default ml-2" 
                          @click="txtDownloadExcel(surveyItem.QUTN_ITEM_CODE, '문항' + (idx + 1) +' 주관식 응답 목록', '주관식 응답')" 
                        >주관식 엑셀 다운로드</v-btn>
                      </div>
                    </div>
                  </div>
                  <!-- 이미지 -->
                  <div class="file-upload-wrap form-inp pt-0 d-flex" v-else-if="surveyItem.QUTN_ITEM_TYPE_CD ==='QUTN_ITEM_TYPE_CD_21' ? true : false" style="width: 100%;">
                    <div style="width: 100%;">
                      <v-text-field
                        value="파일업로드 응답"
                        :name="surveyItem.QUTN_ITEM_CODE"
                        outlined
                        readonly
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex" style="width: 100%;">
                      <v-text-field
                        :value="surveyItem.QUTN_DETL_CNT"
                        :name="surveyItem.QUTN_ITEM_CODE"
                        outlined
                        readonly
                      >
                      </v-text-field>
                      <div class="ml-auto" style="width: 100%;">
                        <v-btn v-if="surveyItem.QUTN_DETL_CNT !== '0'"
                          outlined 
                          small
                          class="btn-default ml-2" 
                          @click="imgFileDownload(surveyItem.QUTN_ITEM_CODE)" 
                        >파일 다운로드</v-btn>
                      </div>
                    </div>
                  </div>
                  <!-- 달력/시간 -->
                  <div class="form-inp pt-0 d-flex" v-else-if="surveyItem.QUTN_ITEM_TYPE_CD === 'QUTN_ITEM_TYPE_CD_30' ? true : false" style="width: 100%;">
                    <div style="width: 100%;">
                      <v-text-field
                        value="달력/시간 응답"
                        :name="surveyItem.QUTN_ITEM_CODE"
                        outlined
                        readonly
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex" style="width: 100%;">
                      <v-text-field
                        :value="surveyItem.QUTN_DETL_CNT"
                        :name="surveyItem.QUTN_ITEM_CODE"
                        outlined
                        readonly
                      >
                      </v-text-field>
                      <div class="ml-auto" style="width: 100%;">
                        <v-btn v-if="surveyItem.QUTN_DETL_CNT !== '0'"
                          outlined 
                          small
                          class="btn-default ml-2" 
                          @click="dateDownloadExcel(surveyItem.QUTN_ITEM_CODE, '문항' + (idx + 1) + ' 달력/시간 응답 목록', '달력/시간 응답')" 
                        >달력/시간 엑셀 다운로드</v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </template>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: "MENU_STM0500", //name은 'MENU_' + 파일명 조합
    
    components: {
    },
    
    data() {
      return {
        // 공통코드
        allCodeList  : [],
        USER_AUTH_CHK: '', // 사용자 권한 체크

        // 조회
        CTCP_CODE  : '',
        CTCP_NAME  : '',
        BUSS_CODE  : '',
        CMP_ID     : '',
        menu1      : false,
        DATE_FROM  : new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        DATE_TO    : new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        TRGT_GUB_CD: 'CUST_TRGT_CD_01',

        // select box
        ctcpTypeCd: [], // 건설사명
        bussTypeCd: [], // 사업지명
        cmpTypeCd : [], // 캠페인명

        // 현황 목록
        gridDataHeaders: [
          { text: "No", value: "index", align: " d-none", width: "0px", sortable: false  },
        ],

        gridDataText: [], // 상담사 목록 데이터
        selGroupCd  : [],
        BUSS_CD     : '',
        BUSS_NM     : '',
        CMPID       : '',
        CMP_NM      : '',

        // 설문지
        cmpQutItem             : [], // 설문지 문항 및 항목
        txtQutnRply            : [], // 주관식 응답 결과
        dateQutnRply           : [], // 달력/날짜 응답 결과
        QUTN_ID                : '',
        QUTN_NAME              : '',

        // 주관식 응답 엑셀다운로드
        txtExcelDataHeaders: [
          { text: "No", value: "index", align: "center", width: "80px", sortable: false, },
          { text: "주관식 내용", value: 'TXT_QUTN_RPLY', align: 'center', width: '250px', sortable: false, },
        ],
        txtExcelData       : [],

        // 달력/시간 응답 엑셀다운로드
        dateExcelDataHeaders: [
          { text: "No", value: "index", align: "center", width: "80px", sortable: false, },
          { text: "날짜/시간", value: 'DATE_QUTN_RPLY', align: 'center', width: '250px', sortable: false, },
        ],
        dateExcelData       : [],

        // 기타
        GE_USER_ID     : '', // 사용자아이디
        monthRange     : 0, // 조회기간차이결과
        dateList       : [], // 날짜범위
        dayRange       : 0, // 날짜범위차이
        EXCEL_QUTN_RPLY: '', // 엑셀 제목
        token          : '', //authToken
        baseUrl        : process.env.VUE_APP_API_URL,
        HEADER_TYPE    : 'BIZ_SERVICE',

        // 메시지
        stm0500Msg: {
          ctcrCdEmptyChk    : '건설사를 선택하세요.',
          bussNameEmptyChk  : '사업지명을 선택하세요.',
          camIdEmptyChk     : '캠페인을 선택하세요.',
          cnslRsvtDtEmptyChk: '조회기간을 선택하세요.',
          cnslRsvtDtChk     : '조회기간은 3개월만 가능합니다.',
        },

        // 페이징
        page        : 1,
        pageCount   : 0,
        itemsPerPage: 30,
        totalVisible: 10,
      };
    },

    methods: {

      // 캠페인현황 조회
      async getListData() {
        this.gridDataHeaders = [];

        // 유효성 체크
        if(!this.searchValidate()) {
          return;
        }

        // 날짜범위
        this.monthRange = await this.mixin_selectDiffDate('MONTH', (this.DATE_FROM).replaceAll('-', ''), (this.DATE_TO).replaceAll('-', ''));
        
        // 날짜범위차이
        this.dayRange = await this.mixin_selectDiffDate('DAY', (this.DATE_FROM).replaceAll('-', ''), (this.DATE_TO).replaceAll('-', ''));

        if(this.monthRange > 3) {
          this.common_alert(this.stm0500Msg.cnslRsvtDtChk, 'chk');
          return;
        }

        this.getDateRangeData(this.DATE_FROM, this.DATE_TO);

        let requestData = {
          headers: {},
          sendData:{},
        };

        //header 세팅
        requestData.headers["URL"] = "/api/report/report-manage/dayRangeCmpQutList";
        requestData.headers["SERVICE"] = "report.report-manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TWB_SQL_ID"] = "selectDayRangeCmpQutList";
        requestData.headers["ASYNC"] = false;
        requestData.headers["GRID_ID"] = "gridDataHeaders";

        //sendData 초기화
        requestData.sendData["CTCP_CODE"] = this.CTCP_CODE;
        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
        requestData.sendData["CMP_ID"] = this.CMP_ID;
        requestData.sendData["START_DATE"] = this.DATE_FROM;
        requestData.sendData["END_DATE"] = this.DATE_TO;
        requestData.sendData["TRGT_GUB_CD"] = this.TRGT_GUB_CD;
        requestData.sendData["DATE_LIST"] = this.dateList;
        requestData.sendData["DAY_RANGE"] = this.dayRange;

        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 캠페인현황 조회 결과
      getGridDataCallBack(res) {
        if(res.HEADER.ERROR_FLAG) {
          this.gridDataText = [];
          this.cmpQutItem = [];
          return;
        }
        this.gridDataHeaders = [];
        
        let data = res.DATA;
        let days = data[0].DAYS;

        this.cmpQutItem = data[0].QUTN_ITEMS;

        this.CMPID = data[0].CMP_ID;
        this.CMP_NM = this.mixin_decode(data[0].CMP_NAME);
        this.QUTN_ID = data[0].QUTN_ID;
        this.QUTN_NAME = this.mixin_decode(data[0].QUTN_NAME);

        let idx = 1;

        // 헤더 추가
        this.gridDataHeaders.push({ text: "날짜", value: "dayCnt",  align: "center", width: "80px", sortable: false },);

        for(var i in days) {
          this.gridDataHeaders.push({ text: this.dateFormat(days[i]), value: "days"+[i],  align: "center", width: "80px", sortable: false },)
        }

        this.gridDataHeaders.push({ text: "합계", value: "TOTAL",  align: "center", width: "80px", sortable: false },);

        let cnslrItems = [];
        // 날짜와 건수 분리
        data[0].RESULT.forEach((element, i) => {
          let cnts = [];

          element.forEach((ele, j) => {
            let items = ele.split(':'); //== 2022-09-01:0
            let dateCnt = items[1];
            cnts.push(dateCnt);
          });
          cnslrItems[i] = cnts;
        });

        // 건수 표기
        if(!this.mixin_isEmpty(cnslrItems)) {
          this.gridDataText = cnslrItems;

          for(var i in this.gridDataText) {
            this.gridDataText[i]["index"] = idx++;
            this.gridDataText[i]["dayCnt"] = '건수';
          }
          idx = 1; // 초기화

          let tot = 0;
          // 데이터 잘라서 가로로 나열하기
          this.gridDataText.forEach((element, i) => {
            element.forEach((ele, j) => {
              let items = ele.split(',');

              tot += parseInt(items[0]);

              this.gridDataText[i]["days"+[j]] = parseInt(items[0]);
              this.gridDataText[i]["TOTAL"] = parseInt(tot);
            });
            tot = 0;
          });
        }else {
          this.gridDataText = [];
        }

        // 엑셀 파일명
        this.EXCEL_QUTN_RPLY = '';
        this.EXCEL_QUTN_RPLY = this.QUTN_NAME; // 설문지명
      },

      // 결과 확인
      result(response) {
        if(response.HEADER.METHOD === "list") {
          this.getGridDataCallBack(response);
        }
      },

      // 주관식 응답 결과 엑셀다운로드
      async txtDownloadExcel(itemCode, fileNm, tableId) {
        this.txtExcelData = [];

        let requestData = {
          headers: {
            'URL': '/api/report/report-manage/dayRangeCmpQutTxtList',
            'SERVICE': 'report.report-manage',
            'METHOD': 'list',
            'TWB_SQL_ID': 'selectDayRangeTxtQutn',
            'ASYNC': false,
          },
          sendData:{
            'CTCP_CODE': this.CTCP_CODE,
            'BUSS_CODE': this.BUSS_CODE,
            'CMP_ID': this.CMP_ID,
            'START_DATE': this.DATE_FROM,
            'END_DATE': this.DATE_TO,
            'TRGT_GUB_CD': this.TRGT_GUB_CD,
            'QUTN_ID': this.QUTN_ID,
            'QUTN_ITEM_CODE': itemCode,
          },
        }

        let response = await this.common_postCall(requestData);

        if(response.HEADER.ERROR_FLAG) {
          this.common_alert('관리자에게 문의해주세요.', 'error');
          return;
        }else {
          let data = response.DATA;
          let idx = 1;

          // 주관식 응답 결과
          this.txtQutnRply = data[0].TXT_QUTN_RPLYS;

          if(!this.mixin_isEmpty(this.txtQutnRply)) {
            this.txtExcelData = [];
            this.txtExcelData = this.txtQutnRply;

            for(var i in this.txtExcelData) {
              this.txtExcelData[i]["index"] = idx++;
              this.txtExcelData[i]["TXT_QUTN_RPLY"] = this.decode(this.txtExcelData[i].TXT_QUTN_RPLY);
            }
          }else {
            this.txtExcelData = [];
          }
        }

        let fileName = this.EXCEL_QUTN_RPLY + '_' + fileNm;
        this.table_id = tableId;

        this.mixin_common_Excel(this.txtExcelDataHeaders, this.txtExcelData, fileName, this.EXCEL_QUTN_RPLY);
      },

      // 파일 다운로드
      async imgFileDownload(itemCode) {
        this.token = this.mixin_getToken(); //authToken 발행
        
        let obj = {
          'CTCP_CODE': this.CTCP_CODE,
          'BUSS_CODE': this.BUSS_CODE,
          'CMP_ID': this.CMP_ID,
          'START_DATE': this.DATE_FROM,
          'END_DATE': this.DATE_TO,
          'TRGT_GUB_CD': this.TRGT_GUB_CD,
          'QUTN_ID': this.QUTN_ID,
          'QUTN_ITEM_CODE': itemCode,
        };

        $.ajax({
          url: this.baseUrl + 'api/report/report-manage/dayRangeCmpQutImgFileDownload',
          type: 'post',
          xhrFields: {responseType: 'blob'},  // 이렇게 사용해야 정상 처리됨.(jquery 3.0 이상) 예) 9kb -> 9kb
          headers: {'Authorization': this.token},
          data: obj,
          success: function (data, status, xhr) {
            try {
              var fileName = xhr.getResponseHeader('X-FileName');
              fileName = decodeURI(fileName);
              var _blob = new Blob([data], {type: xhr.getResponseHeader('content-type')});

              if (window.navigator.msSaveOrOpenBlob) {
                // IE 10+
                window.navigator.msSaveOrOpenBlob(_blob, fileName);
              } else {
                // not IE
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(_blob);
                link.target = "_self";

                if (fileName) link.download = fileName;
                link.click();
              }
            }catch(e) {
              console.error(e);
            }
          },
          error: function (request, status, error) {
            console.log("code:" + request.status + "\n" + "message:" + request.responseText + "\n" + "error:" + error);
          },
        });
      },

      // 달력/시간 응답 결과 엑셀다운로드
      async dateDownloadExcel(itemCode, fileNm, tableId) {
        this.dateExcelData = [];

        let requestData = {
          headers: {
            'URL': '/api/report/report-manage/dayRangeCmpQutDateList',
            'SERVICE': 'report.report-manage',
            'METHOD': 'list',
            'TWB_SQL_ID': 'selectDayRangeDateQutn',
            'ASYNC': false,
          },
          sendData:{
            'CTCP_CODE': this.CTCP_CODE,
            'BUSS_CODE': this.BUSS_CODE,
            'CMP_ID': this.CMP_ID,
            'START_DATE': this.DATE_FROM,
            'END_DATE': this.DATE_TO,
            'TRGT_GUB_CD': this.TRGT_GUB_CD,
            'QUTN_ID': this.QUTN_ID,
            'QUTN_ITEM_CODE': itemCode,
          },
        }

        let response = await this.common_postCall(requestData);

        if(response.HEADER.ERROR_FLAG) {
          this.common_alert('관리자에게 문의해주세요.', 'error');
          return;
        }else {
          let data = response.DATA;
          let idx = 1;

          // 달력/시간 응답 결과
          this.dateQutnRply = data[0].DATE_QUTN_RPLYS;

          if(!this.mixin_isEmpty(this.dateQutnRply)) {
            this.dateExcelData = [];
            this.dateExcelData = this.dateQutnRply;
            
            for(var i in this.dateExcelData) {
              this.dateExcelData[i]["index"] = idx++;
              this.dateExcelData[i]["DATE_QUTN_RPLY"] = this.dateExcelData[i].DATE_QUTN_RPLY;
            }
          }else {
            this.dateExcelData = [];
          }
        }

        let fileName = this.EXCEL_QUTN_RPLY + '_' + fileNm;
        this.table_id = tableId;

        this.mixin_common_Excel(this.dateExcelDataHeaders, this.dateExcelData, fileName, this.EXCEL_QUTN_RPLY);
      },

      // 조회일자 선택
      async startDate(e) {
        this.DATE_FROM = e;
      },

      async endDate(e) {
        this.DATE_TO = e;
      },

      // 두 날짜 사이 날짜 구하기
      getDateRangeData(start, end) {
        this.dateList = [];

        let startDay = new Date(start);
        let endDay = new Date(end);
        while (startDay.getTime() <= endDay.getTime()) {
          let month = startDay.getMonth() + 1;
          month = month < 10 ? '0' + month : month;
          let day = startDay.getDate();
          day = day < 10 ? '0' + day : day;
          this.dateList.push(startDay.getFullYear() + '-' + month + '-' + day);
          startDay.setDate(startDay.getDate() + 1);
        }
        return this.dateList;
      },

      // 날짜 포멧 변경
      dateFormat(date) {
        var newDate = "";
        newDate = date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3');
        return newDate;
      },

      //사업지 코드 가져오기
      async changeBussType(ctcpTypeCd) {
        if(!this.mixin_isEmpty(ctcpTypeCd)) {
          this.BUSS_CODE = '';
          this.bussTypeCd = [];
          this.bussTypeCd.push({ CD: '', CD_NM: '' });
          this.CMP_ID =  "";
          this.cmpTypeCd = [];

          if(this.USER_AUTH_CHK === "SYSTEMMANAGER") {
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd);
          }else {
            this.bussTypeCd = await this.mixin_busscode_get(ctcpTypeCd, this.GE_USER_ID);
          }
        }else {
          this.BUSS_CODE = '';
          this.bussTypeCd = [];
          this.CMP_ID =  "";
          this.cmpTypeCd = [];
        }
      },

      // 캠페인 코드 가져오기
      async changeCmpType(bussTypeCd) {
        if(!this.mixin_isEmpty(bussTypeCd)) {
          this.CMP_ID =  "";
          this.cmpTypeCd = [];
          this.cmpTypeCd.push({ CMP_ID: "", CMP_NAME: "" });
          this.cmpTypeCd = await this.mixin_cmpcode_get(bussTypeCd);
        }else {
          this.CMP_ID =  "";
          this.cmpTypeCd = [];
        }
      },

      // 유효성 체크
      async searchValidate() {
        if(this.mixin_isEmpty(this.CTCP_CODE)) {
          this.common_alert(this.stm0500Msg.ctcrCdEmptyChk, "chk");
          return;
        }

        if(this.mixin_isEmpty(this.BUSS_CODE)) {
          this.common_alert(this.stm0500Msg.bussNameEmptyChk, "chk");
          return;
        }

        if(this.mixin_isEmpty(this.CMP_ID)) {
          this.common_alert(this.stm0500Msg.camIdEmptyChk, "chk");
          return;
        }

        if(this.mixin_isEmpty(this.dateRangeText)) {
          this.common_alert(this.stm0500Msg.cnslRsvtDtEmptyChk, 'chk');
          return;
        }
        return true;
      },

      /**
      * XSS Decode
      */
      decode(strText) {

        //값존재유무 체크
        if (strText === "" || strText === null) {
          return strText;
        }
        strText = strText.toString();

        //문자열 길이가 4이상일 경우만 처리
        if (strText.length <= 3) {
          return strText;
        }

        //순서바뀌면 안나옴
        strText = strText.replaceAll("&lt;", "<");
        strText = strText.replaceAll("&gt;", ">");
        strText = strText.replaceAll("&amp;", "&");
        strText = strText.replaceAll("&quot;", '"');
        strText = strText.replaceAll("&apos;", "'");
        strText = strText.replaceAll("<br>", "\r");
        strText = strText.replaceAll("<p>", "\n");
        strText = strText.replaceAll("&#91;", "[");
        strText = strText.replaceAll("&#93;", "]");
        strText = strText.replaceAll("&#40;", "(");
        strText = strText.replaceAll("&#41;", ")");

        return strText;
      },
    },

    async mounted() {
      let userGroupCd = this.user_role.userGroupCd;
      this.GE_USER_ID = this.user_role.userId;

      let pArr = ['HLW_USE_YN', 'HLW_PLT_AUTH', 'HLW_CTCP_CD'];

      this.allCodeList = await this.mixin_common_code_get_all(pArr);

      if(userGroupCd === "SYSTEMMANAGER") {
        this.USER_AUTH_CHK = userGroupCd;  
        this.ctcpTypeCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD', '전체');
      }else{
        let usserAuth = await this.mixin_buss_ctcp_code_user_auto(this.USER_ID);
        this.ctcpTypeCd = [{ CD_NM: usserAuth[0].CTCP_NAME, CD: usserAuth[0].CTCP_CODE }];
      }
    },

    computed: {
      dateRangeText() {
        var newStartDate = this.DATE_FROM;
        var newEndDate = this.DATE_TO;
        let returnVal = "";

        if(newStartDate !== '' && newEndDate !== '') {
          returnVal = newStartDate + " ~ " + newEndDate
        }
        return returnVal;
      },
      initHeaders() {
        return {
        };
      },
    },
  };
</script>
<style>
</style>